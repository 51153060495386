import React from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { Spin, message } from "antd";
import "./index.less";
const NoData = Loader.loadBaseComponent("NoData");
const Loading = Loader.Loading;
const Wrapper = Loader.loadBaseComponent("TwoColumnLayout");
const SearchImageInput = Loader.loadBusinessComponent("SearchImageInput");
// const ObjectMapPersonnelCard = Loader.loadBusinessComponent(
//   "Card",
//   "ObjectMapPersonnelCard"
// );
const ObjectMapPersonnelCard = Loader.loadBusinessComponent("ObjectMapPersonnelCard");
const SimilarModal = Loader.loadBusinessComponent("ObjectMapPersonnelComponent", "SimilarModal");
const InfiniteScrollLayout = Loader.loadBaseComponent("ReactVirtualized", "InfiniteScrollLayout");
const RefreshButton = Loader.loadBaseComponent("RefreshButton");

@withRouter
@Decorator.withEntryLog()
@Decorator.businessProvider("device", "tab")
@observer
class ObjectMapPersonnelList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchData: {
        keywords: [],
        placeIds: [],
        recommends: [],
        pictureUrl: "",
        limit: 100,
        lastAppearTime:null,
      },
      total: 0,
      loading: true,
      infinitKey: Math.random(),
      list: [],
      count: 0,
      similarVisible: false,
      relationData: {},
      hasLoadMore: false
    };
    this.infinitRef = React.createRef();
    this.cancelHttp = null; // 中断请求
  }

  componentWillMount() {
    this.init();
    SocketEmitter.on(SocketEmitter.eventName.updatePerson, this.updateList);
  }

  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.updatePerson, this.updateList);
  }

  init = () => {
    const { history } = this.props;
    let { searchData } = this.state;
    const id = history.location.search.split("?id=")[1];
    window.LM_DB.get("parameter", id).then(data => {
      if (data) {
        searchData = Object.assign({}, searchData, data.searchData);
      }
      this.setState({
        searchData
      });
      this.InputSearch(searchData, true);
      this.getPersonCount();
    });
  };

  updateList = data => {
    let { list } = this.state;
    const INDEX = list.findIndex(v => v.personId === data.personId || (v.aid && v.aid === data.aid));
    if (INDEX > -1) {
      list[INDEX].isFocus = data.isFocus;
      list[INDEX].tagCodes = data.tags;
      this.setState({ list }, () => {
        this.infinitRef.current.forceUpdateGrid();
      });
    }
  };

  getList = searchData => {
    this.setState({
      loading: true
    });
    return Service.person.queryPersons(searchData).then(res => {
      let listArray = res.data.list;
      let total = res.data.total;
      return {
        listArray,
        total
      };
    });
  };

  refresh = () => {
    let { searchData = {} } = this.state;
    this.InputSearch(searchData);
  };

  InputSearch = (option = {}, first = false) => {
    const { tab, location } = this.props;
    if (!option.pictureUrl && option.keywords.length === 0 && option.recommends.length === 0 && !first) {
      message.info("请输入关键字进行搜索");
      tab.goPage({
        moduleName: "objectMapPersonnel",
        location,
        isUpdate: true
      });
      return;
    }
    let { searchData } = this.state;
    option.lastAppearTime = null;
    option = Object.assign({}, searchData, option);
    if (!first) {
      const id = Utils.uuid();
      window.LM_DB.add("parameter", {
        id,
        searchData: option
      }).then(() => {
        tab.goPage({
          moduleName: "objectMapPersonnelList",
          location,
          action: "replace",
          isUpdate: true,
          data: { id }
        });
      });
    }
    this.cancelHttp && this.cancelHttp("cancalHttp");
    return new Promise((resolve, reject) => {
      this.cancelHttp = reject;
      this.getList(option)
        .then(res => {
          this.cancelHttp = null;
          resolve(res);
        })
        .catch(() => {
          this.setState({ loading: false, list: [] });
        });
    })
      .then(res => {
        if (res.listArray.length === searchData.limit) {
          this.setState({
            hasLoadMore: true
          });
        }
        this.setState({
          loading: false,
          list: res.listArray || [],
          total: res.total || 0,
          searchData: option,
          infinitKey: Math.random()
        });
      })
      .catch(e => {});
  };

  // 获取人员档案总数
  getPersonCount = () => {
    Service.person.getPersonCount().then(res => {
      this.setState({
        count: res.data
      });
    });
  };

  loadMore = () => {
    let { searchData, total, list, hasLoadMore } = this.state;
    this.setState(
      {
        hasLoadMore: false
      },
      () => {
        // if (list.length<=12){
        //   return
        // }
        if (list.length % searchData.limit > 0) {
          // message.warn("没有更多了");
          return;
        }
        // this.setState({
        //   hasLoadMore: true,
        // });
        searchData.lastAppearTime =list[list.length - 1].recentAppearanceTime;
        this.getList(searchData)
          .then(res => {
            list = list.concat(res.listArray);
            if (res.listArray.length === searchData.limit) {
              this.setState({
                hasLoadMore: true,
              });
            }
            this.setState({
              loading: false,
              list,
              total: res.total || 0,
              searchData
            });
          })
          .catch(() => {
            this.setState({
              loading: false,
              list: []
            });
          });
      }
    );
  };

  jumpDetail = option => {
    const { tab } = this.props;
    let moduleName;
    let id = undefined;
    let type = "";
    if (option.personId && option.hasAid) {
      id = option.personId;
      type = "ploy";
    }
    if (option.aid) {
      id = option.aid;
      type = "aid";
    }
    if (!option.aid && !option.hasAid) {
      id = option.personId;
      type = "entry";
    }
    moduleName = "objectMapPersonnelDetail";

    tab.openDetailPage({
      moduleName,
      value: id,
      data: { id },
      beforeAction: async () => {
        await window.LM_DB.add("parameter", { id, url: option.personInfoUrl, type });
      }
    });
  };

  /**设置关注 */
  onFollow = (data, event) => {
    let { list } = this.state;
    event.stopPropagation();
    let option = {
      personId: data.personId,
      aid: data.aid,
      isFocus: !data.isFocus
    };
    Service.person.setOrCancelFocus(option).then(() => {
      const findIndex = list.findIndex(v => (v.personId && v.personId === data.personId) || (v.aid && v.aid === data.aid));
      if (findIndex > -1) {
        list[findIndex].isFocus = !list[findIndex].isFocus;
        SocketEmitter.emit(SocketEmitter.eventName.reverseUpdatePerson, list[findIndex]);
      }
      this.setState({ list }, () => {
        this.infinitRef.current.forceUpdateGrid();
      });
    });
  };

  /**设置关联 */
  onRelation = (data, event) => {
    event.stopPropagation();
    this.setState({
      similarVisible: true,
      relationData: data
    });
  };

  handleSimilarOK = aids => {
    const { relationData } = this.state;
    if (aids.length === 0) {
      aids = [
        {
          aid: "",
          aidUrl: "",
          similarity: 0,
          type: 1
        }
      ];
    }
    let option = {
      personId: relationData.personId,
      aidBindParams: aids
    };
    Service.person.addRelationVids(option).then(res => {
      this.setState({
        similarVisible: false
      });
    });
  };

  handleSimilarCancel = () => {
    this.setState({
      similarVisible: false
    });
  };

  render() {
    const { list, count, similarVisible, relationData, infinitKey, searchData, loading, hasLoadMore } = this.state;
    return (
      <Wrapper
        className="object-map-personnel-list"
        title="人员档案搜索"
        extra={
          <div className="object-map-header-total">
            <span className="total">
              人员档案总数：
              <span className="number">
                {count ? Utils.thousand(count) : 0}
              </span>
            </span>
            <RefreshButton className="header-btn" onClick={this.refresh} />
          </div>
        }
      >
        <div className="list-content">
          <div className="personnel-header">
            <SearchImageInput
              className="object-list-header-search"
              searchData={searchData}
              search={this.InputSearch}
            />
          </div>
          <div className="personnel-center">
            <Spin spinning={loading}>
              {list.length ? (
                <>
                  <InfiniteScrollLayout
                    itemWidth={348}
                    itemHeight={264}
                    pdWidth={80}
                    key={infinitKey}
                    data={list}
                    hasLoadMore={true}
                    hasBackTop={true}
                    ref={this.infinitRef}
                    loadMore={this.loadMore}
                    renderItem={item => (
                      <ObjectMapPersonnelCard
                        data={item}
                        imgUrl={
                          item.portraitPictureUrl ||
                          (item.aidPictureInfos &&
                            item.aidPictureInfos.length > 0 &&
                            item.aidPictureInfos[0].newestPictureUrl) ||
                          ""
                        }
                        aid={item.aid}
                        address={item.address}
                        personId={item.personId}
                        personName={item.personName}
                        isFocus={item.isFocus}
                        tags={item.tagCodes}
                        lastAddress={item.recentAppearanceAddress}
                        lastTime={item.recentAppearanceTime}
                        onClick={this.jumpDetail}
                        onFollow={this.onFollow}
                        onRelation={this.onRelation}
                        hasAid={item.hasAid}
                        relationShow={!!item.personId}
                      />
                    )}
                  />
                </>
              ) : loading ? (
                <Loading />
              ) : (
                <NoData imgType={4} title="符合条件的人员，请重新搜索！" />
              )}
            </Spin>
          </div>
        </div>
        {similarVisible && (
          <SimilarModal
            visible={similarVisible}
            onOk={this.handleSimilarOK}
            onCancel={this.handleSimilarCancel}
            personData={relationData}
          />
        )}
      </Wrapper>
    );
  }
}

export default ObjectMapPersonnelList;
